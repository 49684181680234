import React, {useEffect, useState} from 'react';
import {View, Image, TouchableOpacity, Dimensions} from 'react-native';
import {useLayerSelection} from '../../hooks/useLayerSelectionContext';
import styled from 'styled-components/native';
import Text from '../components/Text';
import * as Device from 'expo-device';
import constants from '../constants/constants';
import colors from '../constants/colors';
import NoaaLogo from '../assets/images/logos/NOAA.png';
import FwcLogo from '../assets/images/logos/fwc-logo.png';
import SarasotaCountyLogo from '../assets/images/logos/visit-sarasota.png';
import LeeCountyLogo from '../assets/images/logos/Lee-County-logo.png';
import SecooraLogo from '../assets/images/logos/SECOORA_Horizontal_FullColor_Transparent.png';
import NaplesLogo from '../assets/images/logos/City-of-Naples-Seal.png';
import CollierCountyLogo from '../assets/images/logos/Collier-county.png';
import FDEPLogo from '../assets/images/logos/FL-DEP-LOGO_White_OL.png';
import SEAFANLogo from '../assets/images/logos/FDEP_SeaFan_logo_cmyk-01.png';
import LogoButton from './LogoButton';
import CitySarasota from '../assets/images/logos/CitySarasota.png';
import PacificMMC from '../assets/images/logos/pacificmmc.png';
import TybeeIslandScience from '../assets/images/logos/TybeeIslandScience.png';
import btnep from '../assets/images/logos/btnep.png';
import GSU from '../assets/images/logos/GSU.png';
import TybeeIslandMaritime from '../assets/images/logos/TybeeIslandMaritime.png';
import LouisianaWildlifeFisheries from '../assets/images/logos/LDWF_Logo_web_360x360.png';

//	Logo outer container and items in it.
const LogoContainer = styled(View)<{isDesktop?: boolean}>`
  ${({isDesktop}) => {
    if (!isDesktop) {
      return `
          width: ${Dimensions.get('screen').width};
		  background-color: ${colors.mote.primary.lightBlue};
		  border: 2px solid ${colors.mote.primary.powderBlue};
		  border-radius: 5px;
      	  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.25);
      `;
    }
    return `
	  width: 350px;
	  background-color: ${colors.mote.primary.lightBlue};
	  border: 2px solid ${colors.mote.primary.powderBlue};
	  border-radius: 5px;
      box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.25);
    `;
  }}
`;

// Box of logos
const LogoSubBox = styled(View)`
a 
  justify-content: space-around;
  flex-direction: row;
  display: flex;
  flex-flow: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  //  border: 1px solid red;
`;

const LogoText = styled(Text)`
  text-align: center;
  font-weight: normal;
  padding: 8px;
`;

const SmallLogo = styled(LogoButton)`
  width: 70px;
  height: 70px;
  //  border: 1px solid green;
`;

const MedLogo = styled(LogoButton)`
  width: 100px;
  height: 70px;
  //  border: 1px solid yellow;
`;

const LongLogo = styled(LogoButton)`
  width: 130px;
  height: 70px;
  //  border: 1px solid blue;
`;

export default function LogoBox() {
  const [deviceType, setDeviceType] = useState<Device.DeviceType | false>(
    false,
  );

  useEffect(() => {
    const getDeviceType = async () => {
      const type = await Device.getDeviceTypeAsync();
      setDeviceType(type);
    };

    getDeviceType();
  });

  return (
    <LogoContainer isDesktop={deviceType == Device.DeviceType.DESKTOP}>
      <LogoText>In Partnership | Click Below!</LogoText>
      <LogoSubBox>
       
        <SmallLogo image={FwcLogo} linkurl={'https://myfwc.com'} />
        <SmallLogo image={NoaaLogo} linkurl={'https://www.noaa.gov'} />
        <LongLogo image={SecooraLogo} linkurl={'https://secoora.org'} />
        <SmallLogo image={FDEPLogo} linkurl={'https://floridadep.gov'} />
        <SmallLogo
          image={SEAFANLogo}
          linkurl={
            'https://floridadep.gov/rcp/coral/content/seafan-southeast-florida-action-network'
          }
        />
        <SmallLogo
          image={SarasotaCountyLogo}
          linkurl={'https://www.visitsarasota.com/'}
        />
        <LongLogo
          image={LeeCountyLogo}
          linkurl={'https://www.visitfortmyers.com/'}
        />
        <LongLogo
          image={CollierCountyLogo}
          linkurl={'https://www.collierparks.com/'}
        />
        <SmallLogo image={NaplesLogo} linkurl={'https://www.naplesgov.com'} />
		    <SmallLogo
          image={CitySarasota}
          linkurl={'https://sarasotafl.gov/smartcity'}
        />
        <SmallLogo
          image={PacificMMC}
          linkurl={'http://www.pacificmmc.org/'}
        />
        <SmallLogo
          image={TybeeIslandScience}
          linkurl={'https://www.tybeemarinescience.org/'}
        />
        <LongLogo
          image={btnep}
          linkurl={'http://btnep.org/'}
        />
        <LongLogo
          image={GSU}
          linkurl={'https://research.georgiasouthern.edu/water-health/'}
        />
        <SmallLogo
          image={TybeeIslandMaritime}
          linkurl={'https://tybeeislandmaritimeacademy.com/'}
        />
        <SmallLogo
          image={LouisianaWildlifeFisheries}
          linkurl={'https://www.wlf.louisiana.gov/'}
        />
      </LogoSubBox>
    </LogoContainer>
  );
}