import React, {useEffect} from 'react';
import * as Google from 'expo-auth-session/providers/google';
import GoogleSignInButton from '../GoogleSignInButton';
import {useGoogleSignInMutation, GoogleOAuthClient} from '../../graphql-types';
import {useToken} from '../../hooks/useTokenContext';
import Constants from 'expo-constants';
import {useNotification} from '../../hooks/useNotificationContext';
import {Platform} from 'react-native';
import {captureException} from '../../sentry';
import * as AuthSession from 'expo-auth-session';

const {google} = Constants.manifest.extra;

export default function GoogleSignIn() {
  const {set: setNotification} = useNotification();
  const [signInUser] = useGoogleSignInMutation({
    fetchPolicy: 'no-cache',
  });

  const {set: setToken} = useToken();

  const [request, response, promptAsync] = Google.useIdTokenAuthRequest({
    iosClientId: google.iosClientId,
    androidClientId: google.androidClientId,
    webClientId: google.webClientId,
    redirectUri: AuthSession.makeRedirectUri({
      native: 'org.visitbeaches.bcrsv2:/sign-in',
    }),
  });

  const signIn = async (idToken: string) => {
    try {
      const {data} = await signInUser({
        variables: {
          idToken,
          client: Platform.select({
            ios: GoogleOAuthClient.Ios,
            android: GoogleOAuthClient.Android,
            default: GoogleOAuthClient.Web,
          }),
        },
      });

      if (data?.googleSignIn?.accessToken) {
        setToken(data.googleSignIn.accessToken);
      }
    } catch (e) {
      setNotification({
        type: 'error',
        message: 'Please try again later.',
      });
    }
  };

  useEffect(() => {
    if (response?.type == 'success') {
      if (response?.params.id_token) {
        signIn(response.params.id_token);
      }
    }

    if (response && response.type != 'success') {
      captureException(new Error('Failed to authenticate with Google Auth'), {
        extra: {
          response,
        },
      });

      setNotification({
        type: 'error',
        message: 'Failed to sign in with Google.',
      });
    }
  }, [response]);

  if (!request) {
    return null;
  }
  
  return (
    <GoogleSignInButton
      onPress={() => {
        promptAsync();
      }}
    />
  );
}
