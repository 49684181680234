import React, {useState, useEffect} from 'react';
import * as Device from 'expo-device';
import Mobile from './mobile';
import Desktop from './desktop';
import Bridge from './components/Bridge';
import WebLoading from './components/WebLoading';
import * as Sentry from '@sentry/browser';
import {ExtraErrorData} from '@sentry/integrations';
import Constants from 'expo-constants';
import {Platform} from 'react-native';
import {Integration} from '@sentry/types';

Sentry.init({
  dsn:
    'https://dfd6e2ad51e542458e484f53a3c7bf00@o466882.ingest.sentry.io/5481580',
  debug: __DEV__ ? true : false,
  environment: __DEV__ ? 'development' : 'production',
  release: Platform.OS + '@' + Constants.version,
  integrations: [new ExtraErrorData({depth: 10}) as Integration],
});

export default function App() {
  const [deviceType, setDeviceType] = useState<Device.DeviceType | false>(
    false,
  );

  useEffect(() => {
    const getDeviceType = async () => {
      let result = await Device.getDeviceTypeAsync();
      setDeviceType(result);
    };

    getDeviceType();
  }, []);

  if (!deviceType) {
    return Platform.select({
      web: <WebLoading />,
      default: null,
    });
  }

  return (
    <Bridge>
      {deviceType === Device.DeviceType.DESKTOP ? <Desktop /> : <Mobile />}
    </Bridge>
  );
}
