import React, {useEffect, useState} from 'react';
import {BeachStackParamList} from '../../../types';
import {useRoute, RouteProp, useNavigation} from '@react-navigation/native';
import {useLoading} from '../../../hooks/useLoadingContext';
import {useGetBeachQuery} from '../../../graphql-types';
import DateTabs from './DateTabs';
import styled from 'styled-components/native';
import {View, ScrollView, Dimensions,} from 'react-native';
import BeachHeader from '../BeachHeader';
import dateFormat from 'dateformat';
import colors from '../../../constants/colors';
import constants from '../../../constants/constants';
import Text from '../../Text';
import BeachReportCategory from './BeachReportCategory';
import {IParameterInfo} from '../../../mobile/components/ParameterInfo';
import * as Device from 'expo-device';

export type BeachReportScreenRouteProps = RouteProp<
  BeachStackParamList,
  'BeachReportScreen'
>;

const Container = styled(View)<{isDesktop?: boolean}>`
  ${({isDesktop}) => {
    if (isDesktop) {
  		return `
 		 	display: flex;
  			flex-grow: 1;
			max-height: ${Dimensions.get('window').height - constants.heightReduce}px;
  		`;
  	}
  	
  	return `
  			display: flex;
  			flex-grow: 1;
  			max-height: ${Dimensions.get('window').height}
  	`;
  	}}
`;

const ContainerScroll = styled(ScrollView)`
`;

const WhenReportHappened = styled(View)`
  background-color: ${colors.mote.primary.powderBlue};
  padding: 10px;
  display: flex;
  align-items: center;
`;

const WhenReportHappenedText = styled(Text)`
  color: ${colors.app.white};
  font-size: 11px;
`;

type BeachReportProps = {
  setParameterInfo: (value: IParameterInfo) => void;
};

/* Takes a report date, returns true if that date is three days old or older. 
	Slightly convoluted since I have to compare year/month/day to make sure I
	don't get false positives on last successive months or years. 
	Get yesterday by subtracting date, for zero this will roll back to last month. */
function threeDaysOld(d) {
	const reportDate = dateFormat(d, 'yyyymmdd');
	const today = new Date()
	const todayDate = dateFormat(today, 'yyyymmdd');
	const yesterdayDate = dateFormat(new Date().setDate(today.getDate() - 1), 'yyyymmdd');
	const isToday = (reportDate === todayDate);
	const isYesterday = (reportDate === yesterdayDate);
	return (!isToday && !isYesterday)
}


export default function BeachReport({setParameterInfo}: BeachReportProps) {
  const navigation = useNavigation();
  const {params} = useRoute<BeachReportScreenRouteProps>();
	const [deviceType, setDeviceType] = useState<Device.DeviceType | false>(
		false,
	);

  const {set: setLoading, clear: clearLoading} = useLoading();
  const {data, loading} = useGetBeachQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: params.beach,
    },
  });

  useEffect(() => {
    if (loading) {
      return setLoading();
    }

    clearLoading();
  }, [loading]);

  useEffect(() => {
    if (data && data.beach) {
      navigation.setOptions({title: data.beach.name});
    }
  }, [data]);

	useEffect(() => {
		const getDeviceType = async () => {
			const type = await Device.getDeviceTypeAsync();
			setDeviceType(type);
		};

		getDeviceType();
	});

  if (!data) {
    return null;
  }

  if (!data.beach) {
    // @todo failed to get beach
    return null;
  }

  if (!data.beach.lastThreeDaysOfReports) {
    // @todo no reports? shouldn't have got this far
    return null;
  }

  const report = data.beach.lastThreeDaysOfReports.find(
    report => report.id === params.report,
  );

  return (
    <Container isDesktop={deviceType == Device.DeviceType.DESKTOP}>
      <BeachHeader name={data.beach.name} location={data.beach.location} />
      <DateTabs
        reports={data.beach.lastThreeDaysOfReports}
        reportId={params.report}
      />
      <WhenReportHappened>
        <WhenReportHappenedText>
          {threeDaysOld(report?.createdAt) ? 
          	dateFormat(report?.createdAt, '"Report made on " dddd, mmmm d, yyyy "at" h:MM TT')
          	: dateFormat(report?.createdAt, '"Report made" DDDD, mmmm d, yyyy "at" h:MM TT')
          }
        </WhenReportHappenedText>
      </WhenReportHappened>
      <ContainerScroll>
		  {report?.beachReport.map((beachReportCategory, idx) => {
			return (
			  <BeachReportCategory
				setParameterInfo={setParameterInfo}
				key={idx}
				beachReportCategory={beachReportCategory}
				index={idx}
			  />
			);
      	})}
      </ContainerScroll>
    </Container>
  );
}
